import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
// Add Components
import Container from "react-bootstrap/Container";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Icon } from "@iconify/react";
import Carousel from "react-bootstrap/Carousel";
import Toast from "react-bootstrap/Toast";
import { getBannerList, getCartCount, getCartInsert, postCartUpdate, userValidate } from "../Services/serviceApi";
import { getTodaySpecial, getCategoryList } from "../Services/serviceApi";
import { useCookies } from 'react-cookie';
import ToastMessage from "../Components/toast-message";
/* import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; */

const Home = () => {
  //const history = useNavigate()
  const [show, setShow] = useState(false);
  const [cartMsg, setCartMsg] = useState('')
  const [banners, setBanners] = useState([]);
  const [specialProducts, setSpecialProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [userData, setUserData] = useState('');
  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
  const userType = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? '' : 'guest';

  const getBanners = () => {
    getBannerList().then(response => {
      setBanners(response.data.banners);
    })
    return [banners];
  }

  const getProducts = () => {
    getTodaySpecial(userId, userType, '1', '')
      .then(response => {
        if (response.status == 1) {
          setSpecialProducts(response.todayspecial)
        }
        else {
          setSpecialProducts([]);
        }
      })
  }

  const getCategories = () => {
    getCategoryList().then(response => {
      setCategories(response.data.data)
    })
  }

  useEffect(() => {
    onQuantityChange();
  }, [])

  useEffect(() => {
    getBanners()
    getProducts()
    getCategories()
  }, []);

  const addToCart = (productId) => {

    getCartInsert(userType, userId, productId)
      .then(response => {
          setUserData(response)
        setCartMsg(response.data.message)
        setShow(true)
        // onQuantityChange(productId, '')
        getProducts()
        setCartCount(response)
      })
  };

  const onQuantityChange = (productId, action) => {
    const updatedProducts = specialProducts.map((product) => {
      if (product.product_id === productId) {
        let currentQuantity = parseInt(product.cart_qty) || 0;
        let updatedQuantity;

        if (action === 'increment') {
          updatedQuantity = currentQuantity + 1;
          setShow(true)
          setCartMsg('Success! Quantity added to your cart. Keep on shopping!')
        } else if (action === 'decrement' && currentQuantity > 0) {
          updatedQuantity = currentQuantity - 1;
          if (updatedQuantity == 0) {
            setShow(true)
            setCartMsg('Product successfully removed from the cart.')
          } else {
            setShow(true)
            setCartMsg('Quantity reduced successfully. Your cart is updated.')
          }
        } else if (action == '') {
          updatedQuantity = 1;
          setShow(true)
        } else {
          return product;
        }
        postCartUpdate(userId, productId, updatedQuantity, userType)
          .then(response => {
              setUserData(response)
            setCartCount(response)
          })
        return {
          ...product,
          cart_qty: updatedQuantity
        };
      }
      return product;
    });
    setSpecialProducts(updatedProducts)
  };

  specialProducts.length = (specialProducts != undefined && specialProducts.length > 8) ? 8 : specialProducts.length;

  const getCartCounts = () => {
    getCartCount(userId, userType).then(response => {
      setCartCount(response)
    })
  }

  const getCarousalItems = () => {
    const content = [];
    banners.map((banner, index) => (
      content.push(<Carousel.Item key={index}>
        {/* <Link> */}
          <img src={banner.banner_img} alt="" />
        {/* </Link> */}
      </Carousel.Item>
      )
    ))
    return content;
  }

  return (
    <div>
      <Header cartCount={cartCount} userData={userData} />
      <section className="slider pt-space mt-space">
        <Container>
          <div className="banner-slider">
            <Carousel>
              {(banners != undefined && banners.length > 0) ? getCarousalItems() : <div className="skeleton"></div>}
            </Carousel>
          </div>
        </Container>
      </section>
      <section className="Category-list pt-space">
        <div className="product-heading">
          <h3>Shop by Category</h3>
        </div>
        {(categories != undefined && categories.length === 0) ?
          <div className="empty-text"><p>Sorry, no categories available at the moment. Please check back later</p></div>
          : <div className="category-type">
            <Container>
              <Row>
                {categories.slice(0, 3).map((category, index) => (
                  <Col lg={4} md={4} xs={12} key={index}>
                    {/* <Link to={`/product-list?cat_id=${btoa(category.id)}`}> */}
                    <Link to={`/category/${category.category_slug}`} state={category.category_slug}>
                      <div className={`category-box ${index === 1 ? 'bg-orange' : 'bg-green'} `}>
                        <div className="Category-title">
                          <span>FRESH</span>
                          <h3>{category.category_name}</h3>
                          <div className={`shop-btn ${index === 1 ? 'btn-orange' : 'btn-green'}  `}>SHOP NOW</div>
                        </div>
                        {/* <div className={`offer-box ${index === 1 ?'offer-orange':'offer-green'}`>
                        <span>
                          Min <br /> <strong>60%</strong> <br />
                          <span className="off-text">OFF</span>
                        </span>
                      </div> */}
                        <div className={`fruits-img`}>
                          <img src={category.image} alt="" />
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
              {/* <div className="view-btn">
              <Link to="/ProductList" className="">
                VIEW ALL
              </Link>
          </div> */}
            </Container>
            {categories && categories.length >3 ?
            <div className="view-btn view-btn-green" >
            <Link to={`/categories`} >
              VIEW ALL
            </Link>
          </div> 
          : ''}
          </div>}
      </section>

      <section className="special-list">
        <div className="product-heading">
          <h3>Today’s Special</h3>
        </div>
        {(specialProducts.length === 0) ? <div className="empty-text"><p>There is no products available in today special. Please check back later</p></div> : <div className="product-list">
          <Container>
            <Row className="gx-3">

              {specialProducts.map((product, index) => (
                <Col lg={3} md={4} xs={6} key={index}>
                  <div className="product-box">
                    <Link to={`/product-detail/${product.product_slug}`} state={{ product }}>
                      <div className="product-img">
                        <img
                          className="zoom-effect"
                          src={product.product_image}
                          alt={product.product_name}
                        />
                        <div className="product-icon">
                          <img src="../assets/images/special-icon.svg " alt="" />
                        </div>
                        {parseInt(product.selling_price)!== 0 && product.selling_price != null ?
                          (<div className="product-offer-box offer-orange">
                            <span>
                              <strong>{Math.round((product.actual_price - product.selling_price) / product.actual_price * 100)}%</strong> <br />
                              <span className="off-text">OFF</span>
                            </span>
                          </div>) : ('')}
                      </div>

                      <div className="product-title">
                        <h4>
                          {product.product_name.charAt(0).toUpperCase() + product.product_name.slice(1)} / {product.product_name2} - {product.variant_name} {product.nic_name}
                        </h4>
                      </div>
                    </Link>
                    {/* <div className="form-option">
                           <Form.Select aria-label="Default select example">
                              <option>1kg</option>
                              <option value="1">5kg</option>
                              <option value="2">10kg</option>
                              <option value="3">20kg</option>
                           </Form.Select>
                      </div> */}
                    <div className="select-card">
                      <Row className="d-flex align-items-center">
                        <Col lg={6} md={6} xs={12}>
                          <div className="price-list">
                            <h5>
                              {<span className="price-tag">₹{(parseInt(product.selling_price)!== 0 && product.selling_price != null) ? product.selling_price : product.actual_price}</span>}
                              {(parseInt(product.selling_price)!== 0 && product.selling_price != null) && <><span className="price-low">₹{product.actual_price}</span> <br /> You save ₹ {product.actual_price - product.selling_price}</>}
                            </h5>
                          </div>
                        </Col>
                        <Col lg={6} md={6} xs={12}>
                          {product.cart_qty === null || product.cart_qty === 0 ? (
                            <div
                              className="cart-btn btn-full"
                            >
                              {product.stock_availability == 0 ? (<p className="out_of_stock">Out of Stock</p>) : (
                                <Link onClick={() => addToCart(product.product_id)} to="/">
                                  <span className="iconify">
                                    <Icon icon="solar:cart-4-linear" />
                                  </span>
                                  <span className="cart-hide">Add to Cart</span>
                                </Link>
                              )}
                            </div>) : (
                            <div
                              className="price-add"
                            > {((product.stock_availability == 0) ?
                              <p className="out_of_stock">Out of Stock</p> :
                              (product.cart_qty !== null || product.cart_qty !== 0) ? (
                                <div className="quantity-price mt-0">
                                  <Button className="qty-button" onClick={() => onQuantityChange(product.product_id, 'decrement')}>
                                    <span className="iconify">
                                      <Icon icon="ic:baseline-minus" />
                                    </span>
                                  </Button>
                                  <InputGroup className="qty-count">
                                    <Form.Control
                                      placeholder="1"
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                      value={product.cart_qty}
                                      onChange={(e) => onQuantityChange(product.product_id, e.target.value)}
                                    />
                                  </InputGroup>
                                  <Button className="qty-button" onClick={() => onQuantityChange(product.product_id, 'increment')}>
                                    <span className="iconify">
                                      <Icon icon="ic:baseline-plus" />
                                    </span>
                                  </Button>
                                </div>
                              ) : ('')
                            )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
          {(specialProducts.length >8) ? <div className="view-btn">
            <Link to={`/today-special/id`} >
              {/*  <Link to={`/today-special`} className=""> */}
              VIEW ALL
            </Link>
          </div> : ''}
        </div>}
      </section>
      <section className="why-product pb-sapce">
        <div className="product-heading">
          <h3>Why choté kisan?</h3>
        </div>
        <div className="why-list">
          <Container>
            <Row>
              <Col lg={3} md={4} xs={6}>
                <div className="whychoose-box">
                  <div className="choose-img">
                    <img
                      data-aos="flip-right"
                      src="../assets/images/save-more 1.svg"
                      alt=""
                    />
                  </div>
                  <h4>Buy More. Save More.</h4>
                  <p>
                    Lorem ipsum is placeholder text commonly used in the
                    graphic, print, and publishing industries for previewing
                    layouts and visual mockups.
                  </p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="whychoose-box">
                  <div className="choose-img">
                    <img
                      data-aos="flip-right"
                      src="../assets/images/clip-path.svg"
                      alt=""
                    />
                  </div>
                  <h4>100% Fresh Products</h4>
                  <p>
                    Lorem ipsum is placeholder text commonly used in the
                    graphic, print, and publishing industries for previewing
                    layouts and visual mockups.
                  </p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="whychoose-box">
                  <div className="choose-img">
                    <img
                      data-aos="flip-right"
                      src="../assets/images/delivery1.svg"
                      alt=""
                    />
                  </div>
                  <h4>Free Delivery</h4>
                  <p>
                    Lorem ipsum is placeholder text commonly used in the
                    graphic, print, and publishing industries for previewing
                    layouts and visual mockups.
                  </p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="whychoose-box">
                  <div className="choose-img">
                    <img
                      data-aos="flip-right"
                      src="../assets/images/logo-icon.svg"
                      alt=""
                    />
                  </div>
                  <h4>Support Farmers</h4>
                  <p>
                    Lorem ipsum is placeholder text commonly used in the
                    graphic, print, and publishing industries for previewing
                    layouts and visual mockups.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section className="delivery-app">
        <Container>
          <div className="app-info">
            <Row className="d-flex align-items-center">
              <Col lg={4} md={4} xs={12}>
                <div className="app-img">
                  <img src="../assets/images/delivery.gif" alt="" />
                </div>
              </Col>
              <Col lg={4} md={4} xs={12}>
                <div className="app-logo">
                  <div className="logo-box">
                    <img src="../assets/images/Logo_animation-2.gif" alt="" />
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} xs={12}>
                <div className="app-detail">
                  <h3>Experience</h3>
                  <h4>choté kisan</h4>
                  <p>Get it delivered at your door steps</p>
                </div>
                <div className="app-download">
                  <Row className="d-flex align-items-center justify-content-center gx-3">
                    <Col lg={6} md={6} xs={7}>
                      <div className="appstore-img mb-2">
                        <Link to="/">
                          <img src="../assets/images/play-store.png" alt="" />
                        </Link>
                      </div>
                      <div className="appstore-img">
                        <Link to="/">
                          <img src="../assets/images/apple-store.png" alt="" />
                        </Link>
                      </div>
                    </Col>
                    <Col lg={6} md={6} xs={5}>
                      <div className="qr-img">
                        <Link to="/">
                          <img src="../assets/images/qr-code.svg" alt="" />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <ToastMessage show={show} setShow={setShow} cartMsg={cartMsg} />
      </section>

      <section className="howit-works  pt-space">
        <div className="product-heading">
          <h3>
            How <span>choté kisan</span> works?
          </h3>
        </div>
        <Container>
          <Row>
            <Col lg={3} md={3} xs={6}>
              <div className="works-box">
                <div className="steps">01</div>
                <div className="works-img">
                  <img src="assets/images/works-img1.jpg" alt="" title="" />
                </div>
                <p>
                  We encourage <br /> our <span>choté kisans</span>
                </p>
              </div>
            </Col>
            <Col lg={3} md={3} xs={6}>
              <div className="works-box">
                <div className="steps">02</div>
                <div className="works-img">
                  <img src="assets/images/works-img2.jpg" alt="" title="" />
                </div>
                <p>
                  We directly purchase <br /> from <span>choté kisans</span>
                </p>
              </div>
            </Col>
            <Col lg={3} md={3} xs={6}>
              <div className="works-box">
                <div className="steps">03</div>
                <div className="works-img">
                  <img src="assets/images/works-img3.jpg" alt="" title="" />
                </div>
                <p>
                  And we deliver the <br /> products to customers
                </p>
              </div>
            </Col>
            <Col lg={3} md={3} xs={6}>
              <div className="works-box">
                <div className="steps">04</div>
                <div className="works-img">
                  <img src="assets/images/works-img4.jpg" alt="" title="" />
                </div>
                <p>
                  Our <span>choté kisans</span> <br /> get benefits every day
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};
export default Home;
