import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ProfileSidemenu from "../Components/ProfileSidemenu";
import { getOrderDetails } from "../Services/serviceApi";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

const OrderDetails = () => {
  const [orders, setorders] = useState([] || '');
  const [address, setAddress] = useState([] || '');
  const [cartPrice, setCartPrice]= useState('');
  const [orderDate, setOrderDate]= useState('');
  const [paymentType, setPaymentType]= useState('');
  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const orderId = atob(searchParams.get('order_id'));

  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
  //const usertype = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? '' : 'guest';

  const orderDetail = () => {
    getOrderDetails(userId, orderId)
      .then(response => {
        setorders(response.data.orderdetails)
        setAddress(response.data.shipping_detail[0])
        setCartPrice(response.data.orderdetails[0].cart_total_price)
        setOrderDate(response.data.order_date)
        setPaymentType(response.data.payment_type)
      })
    return
  }

  useEffect(() => {
    orderDetail();
  }, [])

  return (
    <div>
      <Header />

      <section className="record-box cart-section mt-space pt-space">
        <div className="empty-cart text-center para-text">
          <img
            className="zoom-effect"
            src="../assets/images/no-record.svg"
            alt=""
          />
          <p>No Records Found</p>
        </div>
      </section>

      <section className="my-order mt-space section-pad">
        <Container>
          <Row>
            <Col lg={3} md={4} xs={12}>
              <ProfileSidemenu />
            </Col>


            <Col lg={9} md={8} xs={12} key={orderId} >
              <div className="order-top">
                <h4>Order Details</h4>
              </div>
              <div className="order-card card">
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <div className="order-content">
                      <h4>Shipping Address: </h4>
                      <p>{address.customer_name}</p>
                      <p>
                        {address.customer_address}, {address.customer_area} <br /> {address.customer_landmark}, {address.customer_city} - {address.customer_pincode}
                      </p>
                      <p>
                        Email: {address.customer_email}
                      </p>
                      <p>
                        Phone: {address.customer_mobile}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <div className="order-content text-end mb-0">
                      <p>
                        <h4>Order ID: {orderId}</h4>
                      </p>
                      <p>
                        Ordered On:<strong> {orderDate}</strong>
                      </p>
                      {/* <p>
                        Delivered On:
                        <strong> Tuesday,April 22th 2024, 04:00 pm</strong>
                      </p> */}
                      <p className="text-green">{paymentType === "COD" ? paymentType : `${paymentType} payment`}</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="table-responsive">
                <Table className="table table-bordered order-table">
                  <tr>
                    <th width="10%">Products</th>
                    <th width="50%"></th>
                    <th className="text-center">Price</th>
                    <th width="15%" className="text-center">
                      Qty
                    </th>
                    <th width="15%" className="text-end">
                      Total
                    </th>
                  </tr>
                  {orders.map((product) => (

                    <tr>
                      <td>
                        <div className="cart-img">
                          {/* <Link > */}
                            <img
                              src={product.product_image}
                              alt=""
                              title=""
                            />
                          {/* </Link> */}
                        </div>
                      </td>
                      <td>
                        <div className="cart-price">
                          {/* <Link to="/ProductDetail"> */}
                            <p>{product.product_name} / {product.product_name2} - {product.variant_name} {product.nic_name}
                            </p>

                          {/* </Link> */}
                        </div>
                      </td>
                      <td className="text-center">
                        <p>₹{product.price}</p>
                      </td>
                      <td className="text-center">
                        <p>{product.quantity}</p>
                      </td>
                      <td className="text-end">
                        <p>₹{product.price*product.quantity}</p>
                      </td>
                    </tr>
                  ))}

                </Table>
              </div>
              <div className="detail-footer">
                <Row>
                  <Col lg={6} md={12} xs={12}>

                  </Col>
                    <Col lg={6} md={12} xs={12}>
                      <div className="detail-grand">
                        <p>
                          <label className="text-bold">Order Subtotal </label>
                          <span className="text-bold">₹{cartPrice}</span>
                        </p>
                        {/* <p>
                          <label className="text-bold">
                            Shipping and handling
                          </label>
                          <span className="text-bold"> ₹100.00</span>
                        </p> */}
                        {/* <p>
                        <label className="text-bold">Voucher & Coupon </label>
                        <span className="text-bold">₹0.00</span>
                      </p> */}
                        {/* <p>
                          <label className="text-bold">Tax (5%) </label>
                          <span className="text-bold"> ₹25.00</span>
                        </p> */}
                        <p className="total-line">
                          <label>
                            <strong>Grand Total </strong>
                          </label>
                          <span className="text-success">
                            <strong>₹{parseInt(cartPrice)}</strong>
                          </span>
                        </p>
                      </div>
                    </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
};

export default OrderDetails;
